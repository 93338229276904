import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMastodon } from '@fortawesome/free-brands-svg-icons';
import ReactEmbedGist from 'react-embed-gist';
import MastodonEmbed from "./mastodon_enbed";
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';

import {
    Typography,
    List,
    Card,
    CardContent,
    Button,
    Grid,
    Avatar,
    Link,
    Fab,
    Box,
    FormGroup,
    FormControlLabel,
    Switch,
    Modal,
    Alert,
} from '@mui/material';

const Top = () => {
    interface Answer {
        server: string;
        answer: string;
        id: number;
        answerd_at: Date;
        question: string;
        user_id: string;
        created_at: string;
        nsfw: boolean;
        questioner_user_id: string;
        questioner_server: string;
        not_anonymous: boolean;
    }
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [userQuestions, setUserQuestions] = useState<Answer[]>([]);
    const navigate = useNavigate();
    const [nsfwDisplay, setNsfwDisplay] = useState(false);
    const [policyModalOpen, setPolicyModalOpen] = useState(false);
    const [ruleModalOpen, setRuleModalOpen] = useState(false);

    const handleNsfwChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        setNsfwDisplay(newValue)
    }

    const UTCtoJST = (utcDate: Date): string => {
        const jstDate = moment.utc(utcDate).tz('Asia/Tokyo');
        const formattedDate = jstDate.format('YYYY-MM-DD HH:mm:ss');
        return formattedDate;
    };

    const handleMypageClick = () => {
        navigate("/mypage");
    };

    const handleLoginClick = () => {
        navigate("/login");
    }

    function handleQuestionClick(username: string, server: string){
        navigate("/questionbox/@" + username + "@" + server);
    }

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(
                    `/api/v1/list/answer/public`
                );

                if (!response.ok) {
                    if (response.status === 404) {
                        setErrorMessage('取得に失敗しました');
                    } else {
                        console.error('Request failed with status:', response.status);
                        const errorData = await response.json();
                        setErrorMessage(errorData.error);
                    }
                } else {
                    const data = await response.json();
                    setUserQuestions(data);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
                setErrorMessage(
                    '取得に失敗しました'
                );
                throw new Error("取得に失敗しました");
            }
        };
        fetchQuestions()
    }, []);
    const token = Cookies.get('jwtToken');
    const handleClosePolicyModal = () => {
        setPolicyModalOpen(false);
    };
    const handleCloseRuleModal = () => {
        setRuleModalOpen(false)
    }
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        height: "90%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: "scroll",
    };

    return (
        <div>
            {errorMessage && (<p>{errorMessage}</p>)}
            {userQuestions && (
                <div>
                    <Alert severity="error">
                        誹謗中傷は利用の<a href="https://gist.github.com/YoheiZuho/f08a8a9a1b79b794c843996739060408#file-fediqbrule-md">ルール</a>で禁止されています。<a href="https://gist.github.com/YoheiZuho/f08a8a9a1b79b794c843996739060408#file-fediqbrule-md">ルール</a>を守って質問しましょう。
                    </Alert>
                    <Grid container alignItems='center' justifyContent='center' direction='column'>
                        <Box sx={{ mt: 4, mb: 1 }}>
                            <img src="/fediqb_logo.svg" height={130} alt="FediQB Logo" />
                        </Box>
                        <Typography variant='h4' component='h4' sx={{ mt: 2 }}>
                            FediQBへようこそ！
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            FediQBではMastodonかMisskeyのアカウントで匿名の質問を受け付けることができます！
                        </Typography>
                        {token ? (
                            <Button sx={{ mt: 2, mb: 2 }} onClick={handleMypageClick} variant="contained"><PersonIcon sx={{mr:0.5}}/> マイページ</Button>
                        ) : (
                            <Button sx={{ mt: 2, mb: 2 }} onClick={handleLoginClick} variant="contained"><LoginIcon sx={{mr:0.5}}/> ログイン</Button>
                        )}                        
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Typography variant='h5' component='h5' sx={{ mt: 2 }}>
                                最近の回答（公開で投稿されたもの）
                            </Typography>
                            <List>
                                <FormGroup>
                                    <FormControlLabel control={<Switch defaultChecked={nsfwDisplay} onChange={handleNsfwChange} />} label="NSFW指定された質問を表示する" />
                                </FormGroup>
                                {userQuestions.map((answer) => (
                                    (nsfwDisplay || !answer.nsfw) && (
                                        <Card sx={{ minWidth: 275, mt: 1.5 }} >
                                            <CardContent>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    質問
                                                </Typography>
                                                <Typography variant="h6" component="div" sx={{ wordBreak: "break-all" }}>
                                                    {answer.question}
                                                </Typography>
                                                <Typography sx={{ mt: 1.5, fontSize: 14 }} color="text.secondary">
                                                    回答
                                                </Typography>
                                                <Typography sx={{ mb:1.5, wordBreak: "break-all" }}>
                                                    {answer.answer}
                                                </Typography>
                                                <Grid container>
                                                    <Typography variant="body2" sx={{mr: 1.2 }} color="text.secondary">
                                                        質問者：
                                                        {answer.not_anonymous ? (
                                                            <a href={'https://' + answer.questioner_server + '/@' + answer.questioner_user_id}>
                                                                @{answer.questioner_user_id}@{answer.questioner_server}
                                                            </a>
                                                        ):(
                                                            "匿名の質問者"
                                                        )}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        回答日時：{UTCtoJST(answer.answerd_at)}
                                                    </Typography>
                                                </Grid>
  
                                                <Typography variant="body2" color="text.secondary">
                                                    回答者：<a onClick={() => handleQuestionClick(answer.user_id,answer.server)}>@{answer.user_id}@{answer.server}</a>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    )
                                ))}
                            </List>
                        </Grid>
                        <Modal
                            open={policyModalOpen}
                            onClose={handleClosePolicyModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <ReactEmbedGist gist="yoheizuho/2fa69f3c5095bcc65319e9c89324055c"
                                    wrapperClass="gist__bash"
                                    loadingClass="loading__screen"
                                    titleClass="gist__title"
                                    file="FediQBプライバシーポリシー.md"/>
                            </Box>
                        </Modal>
                        <Modal
                            open={ruleModalOpen}
                            onClose={handleCloseRuleModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <ReactEmbedGist gist="yoheizuho/f08a8a9a1b79b794c843996739060408"
                                    wrapperClass="gist__bash"
                                    loadingClass="loading__screen"
                                    titleClass="gist__title"
                                    file="FediQBrule.md"/>
                            </Box>
                        </Modal>
                        <Grid item xs={12} md={4}>
                            <Typography variant='h5' component='h5' sx={{ mt: 2, mb: 2}}>
                                お知らせ・運営情報
                            </Typography>
                            <Typography sx={{mb:2}}>
                                <a href="https://mstdn.y-zu.org/@fediqb">公式アカウント</a>の情報を表示しています
                            </Typography>
                            <Card variant="outlined" sx={{ height: 600 }}>
                                <MastodonEmbed
                                    instanceUrl="https://mstdn.y-zu.org"
                                    timelineType="profile"
                                    userId="111831645194222185"
                                    profileName="@fediqb"
                                    maxNbPostShow={10}
                                    defaultTheme="light"
                                />
                            </Card>
                            <Typography variant='h5' component='h5' sx={{ mt: 2, mb: 1}}>
                                開発者
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <Grid container sx={{ ml:1 }}>
                                    <Avatar alt="もちもちずきん" src="https://minio.zuiho.moe/accounts/avatars/000/000/025/original/d97560b4bc7ebb7a.png" />
                                    <Link sx={{ mt: 1, ml: 1}} href="https://www.y-zu.org">もちもちずきん</Link>
                                </Grid>
                            </Typography>
                            <Typography variant='h5' component='h5' sx={{ mt: 2, mb: 1}}>
                                規約等
                            </Typography>
                            <Button
                                sx={{ mr: 1 }}
                                onClick={() => setPolicyModalOpen(true)}
                            >
                                プライバシーポリシー
                            </Button>
                            <Button
                                sx={{ mr: 1 }}
                                onClick={() => setRuleModalOpen(true)}
                            >
                                利用のルール
                            </Button>
                            <Typography variant='h5' component='h5' sx={{ mt: 2, mb: 1}}>
                                FediQBをShare
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <Grid container sx={{ ml:1 }}>
                                    <Fab size="medium" href="https://donshare.net/share.html?text=%23FediQB+%E3%81%A7%E8%B3%AA%E5%95%8F%E3%82%92%E5%8B%9F%E9%9B%86%E3%81%97%E3%81%A6%E3%81%BF%E3%81%BE%E3%81%9B%E3%82%93%E3%81%8B%EF%BC%9F%0Ahttps://fediqb.y-zu.org" style={{ background: '#563ACC', color: "white" }} aria-label="edit">
                                        <FontAwesomeIcon size="2x" icon={faMastodon} />
                                    </Fab>
                                    <Fab size="medium" href="https://misskey-hub.net/share/?text=%23FediQB+%E3%81%A7%E8%B3%AA%E5%95%8F%E3%82%92%E5%8B%9F%E9%9B%86%E3%81%97%E3%81%A6%E3%81%BF%E3%81%BE%E3%81%9B%E3%82%93%E3%81%8B%EF%BC%9F%0Ahttps://fediqb.y-zu.org&visibility=public&localOnly=0" sx={{ ml: 1}} style={{ background: 'white', color: "white" }} aria-label="edit">
                                        <img src="/misskey.png" alt="Misskey" height="40px" />
                                    </Fab>
                                </Grid>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};


export default Top;